<template>
  <div class="user-action-report-container">
    <UserActionSearch @handleAutoSearch="handleAutoSearch" />
    <UserActionChart ref="chart" />
    <UserActionTable ref="table" />
  </div>
</template>

<script>
import UserActionSearch from './UserActionSearch'
import UserActionChart from './UserActionChart'
import UserActionTable from './UserActionTable'
export default {
  components: { UserActionSearch, UserActionChart, UserActionTable },
  methods: {
    handleAutoSearch (query) {
      this.$nextTick(() => {
        // 调用ref对象
        this.$refs.table.dealquery(query)
        this.$refs.chart.getChartData(query)
      })
    }
  }
}
</script>

<style lang="less" scoped>
.user-action-report-container {
  margin: 0 10px 10px 10px;
}
</style>
