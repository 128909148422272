<template>
  <div class="user-action-chart-container">
    <div class="chart_box">
      <a-spin class="spin" :spinning="isLoading">
        <div class="chart" ref="chart"></div>
      </a-spin>
    </div>
    <div class="control">
      <div class="title">指标</div>
      <a-menu :selectedKeys="currentChecked" type="inner" class="menu_checked" @select="handleChecked">
        <a-menu-item class="menu_item" v-for="item in checkList" :key="item.value">
          <div :title="item.lable">
            <span>{{ item.lable }}</span>
          </div>
        </a-menu-item>
      </a-menu>
    </div>
  </div>
</template>

<script>
import { userActionChart } from '@/api/reportdatas'
import options from './options'
import { numFormat } from '@/utils/dealNumber'
import * as echarts from 'echarts'
export default {
  data () {
    return {
      currentChecked: ['dau'],
      options,
      myEcharts: null,
      isLoading: false,
      checkList: [
        {
          lable: 'DAU',
          value: 'dau',
          color: 'rgba(183, 105, 247, 1)'
        },
        {
          lable: 'DEU',
          value: 'deu',
          color: 'rgba(95, 233, 196, 1)'
        },
        {
          lable: '渗透率',
          value: 'permeability',
          color: 'rgba(248, 123, 104, 1)'
        },
        {
          lable: '展示 / DAU',
          value: 'aipau',
          color: 'rgba(247, 65, 108, 1)'
        },
        {
          lable: '展示 / DEU',
          value: 'aipu',
          color: 'rgba(31, 125, 238, 1)'
        },
        {
          lable: 'ARPDAU',
          value: 'arpDau',
          color: 'rgba(252, 159, 74, 1)'
        },
        {
          lable: 'ARPDEU',
          value: 'arpDeu',
          color: 'rgb(0, 118, 143)'
        },
        {
          lable: '人均启动次数',
          value: 'startAppDau',
          color: 'rgb(76, 180, 231)'
        }
      ],
      dataList: [],
      lableList: []
    }
  },
  mounted () {
    window.addEventListener('resize', this.getSize)
  },
  destroyed () {
    this.myEcharts && this.myEcharts.dispose()
    window.removeEventListener('resize', this.getSize)
  },
  methods: {
    getSize () {
      // 判断是否是created时调用
      if (this.myEcharts) {
        this.myEcharts.resize()
      }
    },
    handleChecked ({ key }) {
      this.currentChecked = [key]
      this.changeEcharts()
    },
    async getChartData (query) {
      this.isLoading = true
      const resp = await userActionChart(query)
      this.dataList = resp.data && resp.data.list ? resp.data.list : []
      this.lableList = resp.data ? resp.data.lable : []
      await this.changeEcharts()
      this.isLoading = false
    },
    changeEcharts () {
      const item = this.dataList.find((item) => item.field === this.currentChecked[0]) || []
      this.options.series[0].name = item.name || ''
      this.options.series[0].data = item.data || []
      this.options.xAxis.data = this.lableList
      const color = this.checkList.find((item) => item.value === this.currentChecked[0]).color
      this.options.series[0].itemStyle.normal.color = color
      this.options.series[0].itemStyle.normal.lineStyle.color = color
      this.options.series[0].areaStyle = {
        color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0, // 从左到右，但不能从上到下，需要借助辅助系列
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: 'rgba(255, 255, 255, 0)' // 100% 处的颜色
                }
              ],
              globalCoord: false // 缺省为 false
            }
      }
      this.options.tooltip.formatter = (params) => {
        let str = ''
        params.forEach((item, index) => {
          const temp = {}
          if (item.seriesName === '渗透率') {
            temp.value = item.value + '%'
          } else {
            temp.value = numFormat(item.value, 3, '')
          }
          str += item.marker + item.seriesName + ': ' + temp.value + '<br>'
        })
        return params[0].axisValue + '<br>' + str
      }
      this.echartsInit()
    },
    echartsInit () {
      if (!this.$refs.chart) return
      if (this.myEcharts) {
        this.myEcharts.dispose()
        this.myEcharts = null
      }
      this.myEcharts = echarts.init(this.$refs.chart, null, { render: 'svg' })
      this.myEcharts.setOption(this.options)
    }
  }
}
</script>

<style lang="less">
.user-action-chart-container {
  display: flex;
  height: 320px;
  background: #fff;
  margin-top: 10px;
  border-radius: 5px;
  padding: 10px;
  .chart_box {
    flex: 1;
    .spin {
      position: relative;
      height: 100%;
      .ant-spin-container {
        height: 100%;
        .chart {
          height: 100%;
        }
      }
    }
  }
  .control {
    border-radius: 5px;
    border: 1px solid #eee;
    width: 250px;
    max-width: 180px;
    width: 20%;
    overflow-y: hidden;
    .ant-menu-vertical {
      border-right: none;
    }
    .title {
      height: 30px;
      line-height: 30px;
      padding-left: 10px;
      background: #eee;
    }
    .menu_checked .menu_item {
      background: fade(@primary-color, 2%);
      height: 29px;
      line-height: 29px;
      font-size: 13px;
      margin: 3px;
      border-left: 3px solid #fff;
      &.ant-menu-item-selected {
        border-left: 3px solid;
        background: fade(@primary-color, 10%);
      }
    }
  }
}
</style>
