<template>
  <div class="user-action-search-container">
    <a-range-picker
      class="search_item"
      format="YYYY-MM-DD"
      :placeholder="['Start', 'End']"
      @change="ChangeDate"
      :value="rangePickerDate"
      :allowClear="false"
      :ranges="{
        今日: [initDate(0, 0).start, initDate(0, 0).end],
        昨日: [initDate(1, 1).start, initDate(1, 1).end],
        最近一周: [initDate(6, 0).start, initDate(6, 0).end],
        最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
      }"
      :disabledDate="disabledDate"
    >
      <!-- <a-icon slot="suffixIcon" type="calendar" /> -->
    </a-range-picker>
    <m-select-one
      class="search_item"
      hasOs
      label="应用"
      :hasPosition="false"
      :allData="appList"
      v-model="searchquery.appId"
      :allowClear="true"
      @change="changeAppId"
    />
    <m-select-one
      class="search_item"
      hasOs
      label="广告位"
      :hasPosition="false"
      :allData="placeList"
      v-model="searchquery.adPlaceId"
      :allowClear="true"
      @change="changePlaceIds"
    />
    <m-select-one
      class="search_item"
      hasOs
      label="广告样式"
      :hasPosition="false"
      :allData="positionList"
      v-model="searchquery.position"
      :allowClear="true"
      :showId="false"
      @change="handleAutoSearch"
    />
    <m-select-one
      class="search_item"
      hasOs
      label="操作系统"
      :hasPosition="false"
      :allData="[
        { name: '安卓', id: '1' },
        { name: 'IOS', id: '0' }
      ]"
      v-model="searchquery.os"
      :allowClear="true"
      :showId="false"
      @change="handleAutoSearch"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import mixDate from '@/mixins/initDate'
import mixGetList from '@/mixins/getList'
import { getDuration } from '@/utils/duration'
export default {
  mixins: [mixDate, mixGetList],
  data () {
    return {
      searchquery: {
        dateEnd: '',
        dateStart: '',
        appId: '',
        adPlaceId: '',
        position: '',
        osIdList: ''
      }
    }
  },
  computed: {
    ...mapState({
      title: (state) => state.autoweb.title
    })
  },
  created () {
    // 初始化时间
    const { endDate, startDate, end, start } = this.initDate(6, 0)
    this.rangePickerDate = [start, end]
    this.searchquery.dateEnd = endDate
    this.searchquery.dateStart = startDate
    this.getAppList()
    this.getPlaceList()
    this.getPositionList()
    // 进入时搜索
    this.$emit('handleAutoSearch', this.searchquery)
  },
  methods: {
    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间 小于2021.9
      return (current && current < this.initDate(getDuration(), 0).start) || current > this.initDate(0, 0).end
    },
    ChangeDate (date, dateString) {
      this.searchquery = {
        ...this.searchquery,
        dateEnd: dateString[1],
        dateStart: dateString[0]
      }
      this.rangePickerDate = date
      this.handleAutoSearch()
    },
    // 应用改变查广告位
    async changeAppId (id) {
      if (!id) {
        // app无值 通过全部appList查place列表
        await this.getPlaceListByAppIds(this.appList.map((item) => item.id))
      } else {
        // app选了 通过选了的app查place列表
        await this.getPlaceListByAppIds([id])
      }
      this.changePlaceIds([this.searchquery.placeIdList])
    },
    // 广告位改变查流量分组
    async changePlaceIds (id) {
      this.handleAutoSearch()
    },
    handleAutoSearch () {
      this.$emit('handleAutoSearch', this.searchquery)
    }
  }
}
</script>

<style lang="less">
.user-action-search-container {
  background: #fff;
  padding: 20px 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  border-radius: 5px;
  .search_item {
    padding: 0 5px;
    width: 240px;
    display: inline-block;
  }
}
</style>
